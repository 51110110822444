/* 印尼语 */
export default {
  emailRegister: 'Daftar Email',
  registerNotice:
    'Akun yang belum terdaftar akan secara otomatis didaftarkan dan masuk',
  enterEmail: 'Silakan masukkan alamat email Anda',
  enterPhone: 'Silakan masukkan nomor telepon',
  enterCode: 'Silakan masukkan kode Anda',
  getCode: 'Dapatkan kode',
  signIn: 'Masuk sekarang',
  signPropt: 'Dengan masuk, Anda setuju dengan',
  policy: 'Kebijakan Privasi',
  agreement: 'Perjanjian Pengguna',
  emailRuleError: 'Format email tidak benar',
  regisgerAndEnjoy: `Daftar untuk Menikmati Pertunjukan Hebat`,

  starcard: `Keuntungan Keanggotaan Kartu Bintang`,
  more: `Lebih banyak`,
  describe: `Kartu Bintang adalah bukti hak istimewa sistem promosi Jubaopen. Hanya dengan memiliki Kartu Bintang Anda dapat menikmati serangkaian manfaat dan hak istimewa dari sistem promosi Jubaopen. Kartu Bintang terbagi menjadi Kartu Bintang Uji Coba dan Kartu Bintang Eksklusif. Pengguna Kartu Bintang dapat memperoleh hadiah tunai.`,
  starReward: `Hadiah Promosi Kartu Bintang`,
  unlock: `Buka Kunci`,
  text1: `· Penghargaan undangan: Pengundangan langsung untuk menjadi Kartu M dapat mendapatkan pahala 10%.`,
  text2: `· Hadiah promosi investasi hak cipta: Direkt mengundang untuk investasi dalam hak cipta, Anda dapat mendapatkan 10% dari pendapatan harian mengundang.`,
  text3: `· Hadiah Menonton: Undang pengguna secara langsung untuk membuka satu episode dan dapatkan hadiah 40%, atau langganan anggota drama pendek dapatkan hadiah 20%.`,
  starClu: `Hadiah Grup Bintang`,
  text4: `Silakan hubungi layanan pelanggan resmi atau pengundang untuk aturan hadiah yang lebih rinci`,
  copyright: `Crowdfunding Hak Cipta`,
  td1: `Tingkat Pengembalian Tahunan`,
  td2: `Jumlah Investasi Awal`,
  td3: `Selesai/Total Dana`,
  warning: `*Hasil akan berfluktuasi setiap hari sesuai dengan efek promosi aktual.`,
  schedule: `Kemajuan Crowdfunding`,
  buynow: `Beli Sekarang`,
  download: `Unduh`,
  go: `Pergi`,
  android: `Android`,
  apple: `App Store`,
  Characteristic: `Karakteristik`,
  Participation: `Jumlah Partisipasi`,
  method: `Metode Pembayaran`,
  Payamount: `Jumlah Pembayaran`,
  jump: `Lompat ke Pembayaran`,
  name1: `Drama Pendek`,
  name2: `Berbagai Jenis`,
  name3: `Anggota Kartu Bintang`,
  name4: `Pendapatan Saluran`,
  describe1: `Jaminan Kualitas`,
  describe2: `Memikirkan Anda`,
  describe3: `Subsidi Miliaran`,
  describe4: `Pekerjaan Sampingan Utama`,
  warning1: `Konten tidak boleh kosong`,
  success1: `Berhasil Terhubung`,
  ends: `Hitung Mundur Berakhir`,
  warning2: `Anda belum login, silakan login terlebih dahulu`,

  experienceStar: 'Kartu Bintang Pengalaman',
  ExclusiveStar: 'Kartu Bintang Eksklusif',
  noCard: 'Tidak Ada Kartu Bintang',

  aboutus: `Tentang Kami`,
  understand: `Pahami Kami`,
  Snapshort: `Tim ${window.name}:`,
  Innovators: `Inovator`,
  industry: `Industri Film`,
  paragraph1_1: `Di era digital yang selalu berubah, sekelompok pengembang yang mencintai seni film berusaha untuk mengubah pengalaman menonton penonton.`,
  paragraph1_2: `Tim kami, ${window.name}, berfokus pada inovasi dan didorong oleh teknologi, sedang memimpin tren baru di industri film.`,
  paragraph1_3: `${window.name} adalah aplikasi seluler yang berfokus pada konten drama pendek. Kami dengan hati-hati memilih drama pendek berkualitas tinggi untuk menyesuaikan dengan gaya hidup cepat pemirsa modern.`,
  paragraph1_4: `Aplikasi kami memungkinkan pengguna untuk menonton drama pendek terbaru dan terpopuler kapan saja dan di mana saja, sekaligus menyediakan rekomendasi personalisasi yang kaya, sehingga setiap pengguna dapat menemukan konten yang mereka sukai.`,
  paragraph2_1: `Tim kami terdiri dari pengembang berpengalaman dan berketerampilan tinggi yang mahir dalam produksi konten film, analisis data besar, rekomendasi kecerdasan buatan, dan teknologi lainnya.`,
  paragraph2_2: `Ini memungkinkan kami untuk secara efisien memproduksi dan memperbarui konten, serta memberikan rekomendasi yang disesuaikan untuk setiap pengguna.`,
  paragraph2_3: `Visi kami adalah membuat setiap pengguna menemukan kesenangan dan inspirasi mereka dalam gravitasi serial drama. Kami percaya bahwa melalui upaya kami, ${window.name} akan menjadi bagian dari hidup Anda, memberi Anda momen ketenangan dan kebahagiaan di tengah kesibukan hidup.`,
  paragraph3_1: `Apakah Anda mencari hiburan yang menyenangkan atau cerita yang menggugah pikiran, ${window.name} dapat memenuhi kebutuhan Anda.`,
  paragraph3_2: `Mari kita bersama-sama menjelajahi kemungkinan tak terbatas dari seni film.`,

  period: `Kartu Percobaan`,
  Premier: `Kartu Premier`,
  Expiration: `Tanggal Kadaluarsa:`,
  renew: `Perpanjang Sekarang`,
  balance: `Saldo Kartu Bintang`,
  Income: `Pendapatan`,
  wallet: `Transfer ke Dompet`,
  daily: `Tugas Harian`,
  nocompleted: `Belum Selesai Hari Ini`,
  code: `Kode VIP`,
  Residue: `Kode Sisa`,
  reward: `Hadiah Hari Ini`,
  type: `Tipe`,
  amount: `Jumlah`,
  time: `Waktu`,
  none: `Saat ini tidak ada konten lagi`,
  load: `Memuat...`,
  loadmore: `Muat Lebih Banyak`,
  success2: `Berhasil Ditransfer ke Dompet`,
  five: `Pakar Bintang Lima`,
  four: `Pakar Bintang Empat`,
  Samsung: `Pakar Bintang Tiga`,
  two: `Pakar Bintang Dua`,
  one: `Pakar Bintang Satu`,
  recommendation: `Hadiah Rekomendasi`,
  rewards: `Hadiah`,
  taskRewards: `Penghargaan Tugas`,
  starRewards: `Penghargaan Promosi Hak Cipta`,
  MovieRewards: `Penghargaan Promosi Kartu M`,

  annualized: `Tingkat Pengembalian Tahunan`,
  Initial: `Jumlah Investasi Awal`,
  Completed: `Completed`,
  Complete: `Complete`,
  tishi: `*Hasil akan berfluktuasi setiap hari sesuai dengan efek promosi aktual.`,
  Crowdfunding: `Kemajuan Crowdfunding:`,
  dividend: `Saldo Dividen Crowdfunding`,
  Project: `Nama Proyek`,
  Cumulative: `Pendapatan Kumulatif`,
  Dividend: `Tingkat Dividen`,
  investment: `Jumlah Investasi`,
  name5: `Crowdfunding`,
  name6: `Partisipasi`,
  title1: `Sedang Berlangsung`,
  title2: `Selesai`,
  title3: `Catatan Partisipasi`,
  title4: `Detail Penghasilan`,
  text5: `Waktu Pembelian`,
  text6: `Waktu Pelepasan`,
  text7: `Jumlah Total yang Dibagikan`,
  text8: `Jumlah yang Dibagikan`,
  title_9: `Pergantian Akun`,
  title_10: `Keluar`,
  title_11: 'Pertukaran Kode Penebusan',
  title_12: `Pasar Perdagangan`,
  title_13: `Obrolan IM`,

  remaining: `Tugas Tersisa Hari Ini:`,
  Claim: `Batas Waktu Klaim:`,
  Submission: `Batas Waktu Pengajuan:`,
  Completion: `Waktu Penyelesaian:`,
  error: `Pemeriksaan Tugas Gagal, Harus Dikirim Ulang!`,
  warning3: `Saat Mengirim Tugas, Konten Gambar Harus Disertai Tanggal Saat Ini`,
  sample: `Gambar Contoh`,
  Copywriting: `Materi Naskah`,
  Claim2: `Klaim Tugas`,
  SubmitTask: `Kirim Tugas`,
  Waiting: `Menunggu Pemeriksaan`,
  Resubmit: `Kirim Ulang`,
  resubmit: `, Silakan Kirim Ulang!`,
  Expand: `Perluas`,
  Recover: `Pulihkan`,
  Deadline: `Batas Waktu:`,
  text9: `1. Teks dalam gambar harus sesuai dengan teks naskah`,
  text10: `2. Teks dalam gambar harus disertai dengan tanggal saat ini`,
  success3: `Berhasil Diunduh`,
  error2: `Unduhan Gagal`,
  success4: `Berhasil Disalin`,
  warning4: `Silakan unggah tangkapan layar yang telah selesai`,
  success5: `Unggah Berhasil`,

  emaillogin: `Login dengan Email`,
  warning5: `Akun yang belum terdaftar akan otomatis terdaftar dan login`,
  text11: `Dapatkan Kode Verifikasi`,
  Sign: `Login Sekarang`,
  text12: `Dengan login, Anda menyetujui`,
  Privacy: `Kebijakan Privasi`,
  Agreement: `Perjanjian Pengguna`,
  warning6: `Silakan masukkan alamat email Anda`,
  warning7: `Silakan masukkan alamat email yang benar`,
  warning8: `Kode verifikasi tidak boleh kosong`,
  warning9: `Silakan masukkan alamat email Anda`,
  retrieve: `Pemulihan`,
  Payment: `Konfirmasi pembayaran`,
  InformationFilling: `Pengisian Informasi`,
  BalanceCombinationPayment: `Pembayaran Kombinasi Saldo`,
  AvailableBalance: `Saldo Tersedia:`,
  will: `akan mengirimkan kode verifikasi ke`,
  setCode: `kirim kode verifikasi`,
  BalanceDeduction: `Setelah pengurangan saldo, masih perlu membayar`,
  StarClusterLevel: 'Tingkat Kluster Bintang',
  PersonalPerformance: 'Prestasi Pribadi',
  StarClusterPerformance: 'Prestasi Kluster Bintang',
  memberNumber: 'Jumlah anggota',
  NumberStar: 'Jumlah Anggota Bintang',
  NumberDirectly: 'Jumlah Anggota yang Diundang Langsung',
  activeMembers: 'Jumlah Anggota Aktif',

  title_1: `Login`,
  title_2: `Beranda`,
  title_3: `Dompet Saya`,
  title_4: `Kartu Bintang Saya`,
  title_5: `Crowdfunding Hak Cipta`,
  title_6: `Komunitas Saya`,
  title_7: `Tentang Kami`,
  title_8: `Keuntungan Komunitas`,

  interests: `Keuntungan Kartu Bintang`,
  log_fir: `Silakan login terlebih dahulu`,
  name7: `Nonton Gratis`,
  name8: `Tugas Harian`,
  name9: `Sistem Grup Bintang`,
  name10: `Lebih Banyak Hak Istimewa`,
  describe5: `VIP Eksklusif menunggu Anda`,
  describe6: `Tugas Miliaran`,
  describe7: `Undang Teman, Bagikan Hadiah`,
  describe8: `Menunggu Anda untuk Menjelajahi`,

  Invite: `Undang Teman`,
  invitation: `Kode Undangan Saya`,
  directly: `Undang Teman Secara Langsung`,
  indirect: `Teman Tidak Langsung`,
  Copy_link: `Salin Tautan Undangan`,
  Fill: `Isi Kode Undangan`,
  inv_code: `Kode Undangan:`,
  Replicating: `Berhasil Disalin`,
  error3: `Penyalinan Gagal`,
  error4: `Gagal Menempel:`,

  Apple_login: `Login dengan Apple`,
  Google_login: `Login dengan Google`,
  Email_login: `Login dengan Email`,

  Commission: `Komisi:`,
  nolevel: `Saat ini tidak ada tingkat yang tersedia`,
  community: `Jumlah Komunitas`,
  Community1: `Langsung Tingkatkan Kinerja`,
  promoted: `Jumlah Promosi Hari Ini`,
  Promote: `Hadiah Promosi Hak Cipta`,
  balance2: `Saldo Saat Ini`,
  Withdraw: `Tarik`,
  Transfer: `Pemindahan`,
  Interpersonal: `Rekomendasi Teman`,
  mixpayTips: `Prioritas diberikan untuk menggunakan saldo, pembayaran akan dilakukan dengan menggunakan saldo sebesar:`,
  noBalanceL: `Saldo tidak cukup`,

  days: `Hari`,
  Selected: `Dipilih`,
  Select: `Pilih`,
  paid: `Jumlah Pembayaran`,
  gopay: `Bayar Sekarang`,
  warning10: `Silakan pilih metode pembayaran terlebih dahulu`,

  progress: `Pembayaran Sedang Berlangsung`,
  cancel: `Batalkan`,
  confirm: `Konfirmasi`,
  Paid: `Telah Dibayar`,
  warning11: `Pembayaran pesanan Anda tidak berhasil`,

  Remaining: `Sisa Bulan Ini:`,
  Exchange: `Daftar Penukaran`,
  Redemption: `Kode Penukaran`,
  Duration: `Durasi`,
  Period2: `Masa Berlaku`,
  State: `Status`,
  Function: `Fungsi`,
  nouse: `Belum Digunakan`,
  Used: `Telah Digunakan`,
  Copy: `Salin`,

  Wallet2: `Riwayat Perubahan Dompet`,
  Variable: `Jumlah Perubahan (${window.currency})`,
  OrderID: `ID Pesanan`,
  Balance3: `Penarikan Saldo`,
  Balance5: `Transfer bilansi`,
  information: `Isi Informasi`,
  Amount4: `Jumlah Penarikan`,
  Balance6: `Cantidade de transferencia`,
  Balance4: `Saldo:`,
  nomoney: `Saldo Tidak Cukup`,
  crowdfunding: `Partisipasi Crowdfunding`,

  title_name: `Perjanjian Pengguna`,
  t1: `Perjanjian ini berlaku sejak diterbitkan pada 15 Maret 2023, dan terakhir diperbarui pada 15 Maret 2023.`,
  t2: `${window.region}${window.company} Technology Co., Ltd. adalah pemegang hak cipta terdaftar dari perangkat lunak ini, dan memiliki hak cipta atas perangkat lunak ini secara hukum. Perjanjian pengguna ini (selanjutnya disebut sebagai "Perjanjian ini") adalah kontrak yang sah antara ${window.company} Technology Co., Ltd. (selanjutnya disebut "${window.company} Technology", "kami") dan pengguna (juga disebut sebagai "Anda") mengenai instalasi dan penggunaan produk perangkat lunak klien "Juyouli" (selanjutnya disebut sebagai "perangkat lunak ini"). Dengan mengklik untuk mengonfirmasi Perjanjian ini atau dengan cara lain memilih untuk menerima Perjanjian ini, Anda telah mencapai kesepakatan dengan ${window.company} Technology dan setuju untuk menerima semua ketentuan Perjanjian ini. Perangkat lunak ini dioperasikan oleh ${window.company} Technology, dan menyediakan layanan video kepada Anda, menikmati hak-hak dan memenuhi kewajiban, serta bertanggung jawab secara hukum.`,
  t3: `Setelah Perjanjian ini diubah, perangkat lunak akan memberikan pemberitahuan modifikasi di halaman perangkat lunak. Setelah modifikasi perjanjian penggunaan yang ditampilkan di halaman pengumuman, perjanjian tersebut menggantikan perjanjian asli. Anda harus masuk ke perangkat lunak ini secara teratur untuk melihat perjanjian terbaru. Sebelum menggunakan layanan yang disediakan oleh perangkat lunak ini, Anda harus membaca dengan seksama Perjanjian ini. Jika Anda tidak setuju dengan Perjanjian yang telah diubah, Anda dapat secara sukarela membatalkan layanan yang disediakan oleh perangkat lunak ini. Jika tidak, penggunaan Anda akan dianggap sebagai penerimaan Anda terhadap semua konten Perjanjian ini dan modifikasinya.`,
  t4: `1. Bagaimana Kami Mengumpulkan dan Menggunakan Informasi Pribadi Anda`,
  t5: `Informasi pribadi adalah informasi yang direkam dalam bentuk elektronik atau lainnya yang dapat digunakan sendiri atau digabungkan dengan informasi lainnya untuk mengidentifikasi identitas individu tertentu atau mencerminkan aktivitas individu tertentu.`,
  t6: `${window.company} Technology dan pemegang hak cipta terdaftar dari produk ini akan mengumpulkan dan menggunakan informasi pribadi Anda hanya sesuai dengan tujuan, cara, dan ruang lingkup yang dijelaskan dalam kebijakan ini saat menyediakan layanan kepada Anda:`,
  t7: `1. Fungsi aplikasi 1: Menjadi pengguna terdaftar`,
  t8: `Saat mendaftar menjadi pengguna terdaftar dari produk ini, Anda perlu memberikan informasi berikut: Informasi perangkat Anda.`,
  t9: `Informasi yang Anda berikan di atas memberi kami izin untuk terus menggunakan layanan ini kepada Anda. Saat Anda membatalkan akun Anda, kami akan berhenti menggunakan dan menghapus informasi di atas.`,
  t10: `2. Fungsi aplikasi 2: Menampilkan log operasi dalam aplikasi`,
  t11: `Saat Anda menggunakan layanan kami, produk ini akan mencatat log operasi Anda, termasuk catatan penjelajahan dan klik, menambahkan acara TV, menonton acara TV, serta berpartisipasi dalam aktivitas. Informasi yang Anda berikan di atas memberi kami izin untuk terus menggunakan layanan ini kepada Anda. Saat Anda membatalkan akun Anda, kami akan berhenti menggunakan dan menghapus informasi di atas.`,
  t12: `3. Fungsi aplikasi tiga: Berlangganan dan pembayaran`,
  t13: `Saat Anda menggunakan produk ini, catatan yang dihasilkan saat berlangganan keanggotaan VIP dan layanan pembayaran serial, termasuk catatan berlangganan dan catatan konsumsi, akan disimpan di server kami. Informasi di atas diperlukan untuk fungsi produk. Jika Anda tidak setuju dengan kami mencatat informasi di atas, kami tidak akan dapat menyediakan layanan pembelian serial kepada Anda.`,
  t14: `4. Fungsi aplikasi empat: Layanan keamanan`,
  t15: `Untuk menyediakan layanan yang aman dan stabil, melindungi akun dan aset Anda, serta mencegah login palsu, untuk penggunaan normal fungsi dan layanan produk ini, kami mungkin secara otomatis mencatat informasi tertentu, termasuk: Informasi perangkat atau perangkat lunak: Konfigurasi yang diberikan oleh perangkat seluler Anda, browser web, atau program lain yang digunakan untuk mengakses layanan kami, alamat IP Anda, versi perangkat lunak yang digunakan oleh perangkat seluler Anda, kode identifikasi perangkat (IMEI, alamat MAC), pengidentifikasi unik perangkat (Android ID, OAID), serta nomor seri perangkat. Jika Anda tidak setuju dengan kami mencatat informasi di atas, Anda mungkin tidak dapat menyelesaikan proses kontrol risiko dan menggunakan produk kami. Kami juga mungkin secara otomatis mencatat apakah Anda telah menginstal aplikasi Alipay. Jika Anda tidak setuju dengan kami mencatat informasi di atas, penggunaan normal Anda atas produk ini tidak akan terpengaruh.`,
  t16: `5. Fungsi aplikasi lima: Mengaktifkan izin`,
  t17: `Kami berkomitmen untuk tidak melebihi ruang lingkup atau frekuensi pengumpulan informasi pribadi Anda dan tidak akan pernah mengumpulkan atau menggunakan informasi yang tidak perlu atau tidak memiliki skenario aplikasi yang wajar dalam layanan produk ini.`,
  t18: `Saat menggunakan produk ini, untuk menyediakan layanan kepada Anda, produk ini mungkin meminta izin perangkat akhir dalam beberapa skenario aplikasi. Tentu saja, Anda dapat menolak mengaktifkan izin berikut, atau mematikan izin berikut di pengaturan ponsel Anda. Menonaktifkan izin berikut tidak akan mempengaruhi penggunaan Anda atas fungsi lain dari produk ini:`,
  t19: `Akses Telepon: Dengan mendapatkan izin status telepon Anda, kami mencatat kode identifikasi perangkat Anda (yaitu, kode IMEI atau IMSI) untuk diproses secara anonim, digunakan untuk tampilan iklan dan verifikasi keamanan;`,
  t20: `Akses Penyimpanan Lokal: Dengan mengaktifkan izin penyimpanan lokal, serial dalam produk ini akan diunduh ke ruang penyimpanan ponsel Anda;`,
  t21: `Akses Galeri: Anda dapat memilih foto atau gambar dari ponsel Anda untuk diunggah atau menyediakan bukti saat berkomunikasi dengan layanan pelanggan;`,
  t22: `Akses Konten dan File Media di Perangkat: Digunakan untuk menyimpan dan membaca sampul serial dan gambar aktivitas untuk meningkatkan kelancaran penggunaan aplikasi.`,
  t23: `Mengaktifkan Data Nirkabel: Anda dapat mengaktifkan fungsi jaringan yang diperlukan oleh produk ini melalui koneksi jaringan nirkabel atau seluler;`,
  t24: `Akses Daftar Aplikasi Terinstal: Dengan mendapatkan daftar aplikasi terinstal Anda, kami dapat memastikan apakah Anda telah menginstal produk yang kami promosikan, sehingga kami dapat segera mendistribusikan hadiah yang sesuai kepada Anda.`,
  t25: `Mengaktifkan Mikrofon dan Pengakuan Suara: Anda dapat mengontrol produk ini dengan fungsi kontrol suara dan fungsi pembacaan suara;`,
  t26: `Menggunakan Clipboard: Anda dapat menyalin dan menempel nomor layanan pelanggan, atau memilih untuk tidak menggunakan izin clipboard.`,
  t27: `Mendapatkan Izin Jaringan Perangkat, digunakan untuk menggunakan kamus, ensiklopedia, dan pembacaan suara;`,
  t28: `Mendapatkan Lokasi Kasar: Mendapatkan izin lokasi kasar saat menelusuri iklan tertentu;`,
  t29: `Akses Lokasi Pencarian: Mendapatkan izin lokasi pencarian saat menelusuri iklan tertentu;`,
  t30: `Menggunakan Kamera: Izin penggunaan kamera mungkin diberikan saat pengguna memberikan umpan balik.`,
  t31: `2. Bagaimana Kami Menggunakan Cookie dan Teknologi Serupa`,
  t32: `Cookie dan teknologi serupa digunakan secara luas di internet. Untuk memastikan operasi normal dari platform ini, dan untuk memberi Anda pengalaman akses yang lebih mudah, kami mungkin menyimpan file data kecil yang disebut Cookie di perangkat seluler Anda. Cookie biasanya berisi pengidentifikasi, nama situs, serta beberapa angka dan karakter. Dengan menggunakan Cookie, kami akan mencatat informasi login dan operasi Anda dalam produk ini, sehingga kami dapat menyimpan kebiasaan penggunaan Anda dan membantu menentukan status keamanan akun Anda, menyediakan pengalaman dan layanan pengguna yang lebih mudah.`,
  t33: `Kami tidak akan menggunakan Cookie untuk tujuan lain selain yang dijelaskan dalam kebijakan ini. Anda dapat mengelola atau menonaktifkan Cookie sesuai dengan preferensi Anda. Untuk informasi lebih lanjut, silakan kunjungi AboutCookies.org. Jika Anda menonaktifkan Cookie, Anda mungkin tidak dapat menikmati pengalaman layanan terbaik, dan konten yang kami rekomendasikan mungkin tidak terlalu relevan dengan minat Anda.`,
  t34: `3. Bagaimana Kami Membagikan, Mentransfer, dan Mengungkapkan Informasi Pribadi Anda`,
  t35: `(1) Berbagi`,
  t36: `Kecuali dalam situasi berikut, kami tidak akan membagikan informasi pribadi Anda dengan perusahaan, organisasi, atau individu mana pun di luar ${window.company} Technology dan pemegang hak cipta terdaftar produk ini dan perusahaan terkait:`,
  t37: `1. Berbagi setelah mendapatkan persetujuan yang jelas: Setelah mendapatkan persetujuan yang jelas dari Anda, kami akan membagikan informasi pribadi Anda dengan pihak lain.`,
  t38: `2. Kami mungkin membagikan informasi pribadi Anda dengan publik sesuai dengan peraturan hukum atau peraturan wajib dari badan pengawas pemerintah.`,
  t39: `3. Berbagi dengan mitra yang berwenang: Beberapa layanan kami akan disediakan oleh mitra yang berwenang, hanya untuk tujuan yang dijelaskan dalam kebijakan ini. Kami mungkin berbagi sebagian informasi pribadi Anda dengan mitra kami untuk menyediakan layanan pelanggan dan pengalaman pengguna yang lebih baik. Kami hanya akan berbagi informasi pribadi yang diperlukan untuk menyediakan layanan, dan mitra kami tidak berhak menggunakan informasi pribadi yang dibagikan untuk tujuan lain.`,
  t40: `Saat ini, mitra yang berwenang kami termasuk jenis berikut:`,
  t41: `1) Pemasok, penyedia layanan, agen pihak ketiga, dan mitra lainnya. Kami mengirimkan informasi ke pemasok, penyedia layanan, agen pihak ketiga, dan mitra lainnya yang mendukung bisnis kami secara global, termasuk menyediakan layanan infrastruktur teknis, menganalisis penggunaan layanan kami, mengukur efektivitasnya, menyediakan layanan pelanggan, memfasilitasi pembayaran, atau melakukan penelitian dan survei akademis. Dengan persetujuan Anda sebelumnya yang jelas, kami mungkin berbagi catatan konsumsi, catatan berlangganan, catatan pengisian ulang, catatan pesanan, catatan menonton yang telah dihapus, dan catatan konsumsi aktivitas dengan agen pihak ketiga setelah dienkripsi dan dianonimkan. Jika Anda tidak setuju dengan kami berbagi informasi di atas, ini tidak akan mempengaruhi penggunaan normal Anda atas produk ini.`,
  t42: `2) Penyedia layanan data analisis: Untuk memberikan layanan yang lebih baik kepada Anda, kami bekerja sama dengan penyedia layanan data pihak ketiga, termasuk Youmeng+, untuk memprediksi preferensi karakteristik Anda. Untuk memastikan keamanan data Anda, prediksi ini diberikan dalam bentuk skor indeks dan analisis data dilakukan di bawah tindakan keamanan de-identifikasi yang ketat untuk mencegah kebocoran informasi pribadi Anda.`,
  t43: `3) Mitra layanan iklan dan analisis yang berwenang. Kecuali kami mendapatkan izin Anda, kami tidak akan membagikan informasi pengidentifikasi pribadi Anda (informasi yang dapat mengidentifikasi identitas Anda dan dapat digunakan untuk menghubungi atau mengidentifikasi Anda) dengan mitra layanan iklan dan analisis. Namun, kami mungkin berbagi informasi pribadi Anda yang telah dianonimkan dengan mitra layanan iklan dan analisis yang berwenang, termasuk: informasi identifikasi jaringan (alamat IP), nomor versi perangkat lunak, nama paket perangkat lunak, daftar aplikasi terinstal, informasi koneksi perangkat (operator telekomunikasi, lingkungan jaringan), informasi atribut perangkat (produsen perangkat, model perangkat, versi sistem operasi, IMEI, OAID, IMSI, sensor, nomor seri perangkat), untuk membantu mereka meningkatkan efektivitas iklan tanpa mengidentifikasi identitas Anda.`,
  t44: `Layanan SDK pihak ketiga memerlukan izin. Produk dan layanan kami mungkin mencakup produk dan layanan pihak ketiga, serta tautan ke situs web pihak ketiga. Saat Anda menggunakan produk atau layanan ini, informasi Anda mungkin juga akan dikumpulkan. Setelah pengguna memberikan izin yang jelas, informasi pribadi akan dikirimkan kepada pihak ketiga. Saat identitas pihak ketiga berubah, pengguna akan diminta kembali untuk memberikan izin yang jelas, dan kami akan berbagi informasi pribadi dengan pihak ketiga.`,
  t45: `(2) Transfer`,
  t46: `Kecuali dalam situasi berikut, kami tidak akan mentransfer informasi pribadi Anda ke perusahaan, organisasi, atau individu mana pun:`,
  t47: `1. Setelah mendapatkan persetujuan yang jelas dari Anda, kami akan mentransfer informasi pribadi Anda kepada pihak lain;`,
  t48: `2. Dalam kasus merger, akuisisi, atau kebangkrutan likuidasi, jika transfer informasi pribadi terlibat, kami akan meminta perusahaan atau organisasi yang memegang informasi pribadi Anda yang baru untuk terus tunduk pada kebijakan privasi ini. Jika tidak, kami akan meminta perusahaan atau organisasi tersebut untuk kembali meminta izin dan persetujuan Anda.`,
  t49: `(3) Pengungkapan`,
  t50: `Kami hanya akan mengungkapkan informasi pribadi Anda dalam situasi berikut:`,
  t51: `1. Setelah mendapatkan persetujuan yang jelas dari Anda;`,
  t52: `2. Pengungkapan hukum: Kami mungkin mengungkapkan informasi pribadi Anda sesuai dengan hukum, prosedur, proses litigasi, atau persyaratan wajib dari badan pemerintah.`,
  t53: `(4) Jenis informasi pribadi yang mungkin terlibat dalam berbagi eksternal termasuk kode identifikasi perangkat dan informasi versi produk; jenis informasi pribadi yang mungkin terlibat dalam pengungkapan termasuk menonton serial drama dan durasi menonton.`,
  t54: `Kami memahami tanggung jawab hukum yang terkait dengan berbagi, mentransfer, dan mengungkapkan informasi pribadi kepada publik, dan akan mengambil langkah-langkah yang wajar untuk memastikan keamanan informasi pribadi.`,
  t55: `4. Bagaimana Kami Melindungi dan Menyimpan Informasi Pribadi Anda`,
  t56: `(1) Langkah-langkah perlindungan teknis`,
  t57: `Kami sangat memperhatikan keamanan informasi pribadi Anda dan akan berusaha untuk mengambil berbagai langkah keamanan yang wajar sesuai dengan standar industri untuk melindungi informasi Anda dari kebocoran, kerusakan, atau kehilangan, termasuk tetapi tidak terbatas pada SSL, enkripsi penyimpanan informasi privasi, dan kontrol akses ke pusat data. Kami akan menggunakan teknologi enkripsi untuk meningkatkan keamanan informasi pribadi; kami akan menggunakan mekanisme perlindungan yang dapat dipercaya untuk mencegah serangan jahat terhadap informasi pribadi; kami akan menerapkan mekanisme kontrol akses untuk memastikan bahwa hanya personel yang berwenang yang dapat mengakses informasi pribadi.`,
  t58: `(2) Sistem manajemen keamanan`,
  t59: `Kami telah membangun sistem manajemen keamanan data yang terdepan di industri yang berpusat pada siklus hidup data, dari struktur organisasi, desain sistem, manajemen personel, teknologi produk, dan penilaian dampak keamanan informasi pribadi untuk meningkatkan keamanan seluruh sistem.`,
  t60: `Kami juga menerapkan langkah-langkah manajemen yang ketat terhadap karyawan atau personel outsourcing yang mungkin mengakses informasi Anda, termasuk tetapi tidak terbatas pada pengendalian izin yang berbeda sesuai dengan posisi mereka, menandatangani perjanjian kerahasiaan, dan memantau operasi mereka.`,
  t61: `(3) Perlindungan akun`,
  t62: `Akun Anda memiliki fungsi perlindungan keamanan, harap jaga informasi akun dan kata sandi Anda dengan baik. Jika Anda menemukan bahwa akun Anda telah dicuri atau informasi pribadi Anda telah bocor, harap hubungi kami segera agar kami dapat mengambil langkah-langkah yang sesuai.`,
  t63: `(4) Penanganan insiden keamanan informasi`,
  t64: `Meskipun langkah-langkah keamanan di atas telah diterapkan, harap dipahami bahwa tidak ada langkah-langkah yang benar-benar aman di jaringan informasi.`,
  t65: `Jika terjadi insiden keamanan informasi pribadi, kami akan memberitahu Anda sesuai dengan persyaratan hukum: keadaan dasar dari insiden keamanan dan dampak yang mungkin terjadi, langkah-langkah penanganan yang telah atau akan kami ambil, saran untuk Anda secara independen mencegah dan mengurangi risiko, serta langkah-langkah pemulihan yang kami sediakan untuk Anda. Kami akan memberi tahu Anda tentang situasi terkait insiden melalui telepon atau pemberitahuan. Jika sulit untuk memberi tahu satu per satu subjek informasi pribadi, kami akan mengambil langkah-langkah yang wajar dan efektif untuk mengeluarkan pengumuman. Pada saat yang sama, kami juga akan melaporkan penanganan insiden keamanan informasi pribadi sesuai dengan persyaratan regulasi.`,
  t66: `(5) Periode penyimpanan`,
  t67: `Kami hanya akan menyimpan informasi pribadi Anda di daratan Cina. Kami akan mengambil semua langkah yang wajar untuk memastikan bahwa informasi pribadi yang tidak relevan tidak dikumpulkan. Kami hanya akan menyimpan informasi pribadi Anda untuk jangka waktu terpendek yang diwajibkan oleh hukum, untuk mencapai tujuan yang ditetapkan dalam kebijakan ini, kecuali diwajibkan atau diizinkan oleh hukum untuk memperpanjang periode penyimpanan.`,
  t68: `Setelah periode penyimpanan berakhir, kami akan menghapus informasi pribadi Anda sesuai dengan persyaratan hukum atau menganonimkannya.`,
  t69: `5. Hak-hak Anda`,
  t70: `Sesuai dengan hukum, peraturan, dan standar yang relevan di Tiongkok, kami memastikan bahwa Anda dapat menggunakan hak-hak berikut atas informasi pribadi Anda:`,
  t71: `(1) Akses informasi pribadi Anda`,
  t72: `Anda memiliki hak untuk mengakses informasi pribadi Anda melalui pusat pengguna Anda sendiri. Harap merujuk pada (VI) Bagaimana Kami Menanggapi Permintaan Anda, kecuali ditentukan lain oleh hukum dan peraturan.`,
  t73: `(2) Koreksi informasi pribadi Anda`,
  t74: `Saat Anda menemukan bahwa informasi pribadi Anda yang kami proses salah, Anda memiliki hak untuk meminta kami mengoreksinya. Silakan merujuk pada (6) Bagaimana Kami Menanggapi Permintaan Anda untuk informasi lebih lanjut tentang cara mengoreksi informasi pribadi.`,
  t75: `(3) Hapus informasi pribadi Anda. Dalam situasi berikut, Anda dapat meminta kami untuk menghapus informasi pribadi Anda:`,
  t76: `1. Kami memproses informasi pribadi secara ilegal;`,
  t77: `2. Kami mengumpulkan dan menggunakan informasi pribadi Anda tanpa persetujuan Anda;`,
  t78: `3. Kami melanggar kesepakatan dengan Anda saat memproses informasi pribadi;`,
  t79: `4. Anda tidak lagi menggunakan produk atau layanan kami, atau Anda telah membatalkan akun Anda;`,
  t80: `5. Kami tidak lagi menyediakan produk atau layanan kepada Anda.`,
  t81: `Anda dapat menemukan cara menghapus informasi pribadi di (VI) Bagaimana Kami Menanggapi Permintaan Anda. Jika kami memutuskan untuk memenuhi permintaan Anda, kami juga akan memberi tahu entitas yang telah memperoleh informasi pribadi Anda dari kami untuk segera menghapusnya, kecuali ditentukan lain oleh hukum dan peraturan atau Anda memberikan izin terpisah.`,
  t82: `Saat Anda menghapus informasi dari layanan kami, kami mungkin tidak segera menghapus informasi yang sesuai dari sistem cadangan, tetapi kami akan menghapus informasi ini selama periode pembaruan cadangan.`,
  t83: `(4) Cabut persetujuan dan nonaktifkan penggunaan fungsi`,
  t84: `Anda dapat mengubah ruang lingkup otorisasi pengumpulan informasi pribadi kami atau mencabut otorisasi Anda dengan menonaktifkan fungsi perangkat. Anda juga dapat mencabut semua otorisasi pengumpulan informasi pribadi Anda dengan membatalkan akun Anda. Anda dapat menemukan cara mencabut persetujuan dan nonaktifkan penggunaan fungsi di (VI) Bagaimana Kami Menanggapi Permintaan Anda.`,
  t85: `(5) Manajemen rekomendasi personalisasi`,
  t86: `1. Mengelola rekomendasi informasi iklan personalisasi`,
  t87: `Iklan personalisasi adalah teknologi tampilan iklan yang berdasarkan preferensi Anda dalam aplikasi. Jika Anda tidak ingin menerima rekomendasi iklan personalisasi, Anda dapat memilih untuk menonaktifkan rekomendasi iklan personalisasi di sakelar [Saya] - [Pengaturan Sistem] - [Rekomendasi Iklan Personalisasi]. Setelah dinonaktifkan, relevansi iklan yang Anda lihat akan berkurang.`,
  t88: `2. Mengelola rekomendasi konten personalisasi`,
  t89: `Untuk memastikan Anda dapat menonton konten yang menarik bagi Anda, kami menyediakan fungsi rekomendasi konten personalisasi. Jika Anda tidak ingin menerima rekomendasi konten personalisasi, Anda dapat menonaktifkan rekomendasi konten personalisasi di sakelar [Saya] - [Pengaturan Sistem] - [Rekomendasi Konten Personalisasi]. Setelah dinonaktifkan, halaman beranda tidak akan menampilkan saluran yang direkomendasikan. Beralih ke saluran yang dipilih akan mengurangi relevansi konten.`,
  t90: `(6) Pembatalan akun`,
  t91: "Anda dapat membatalkan akun Anda di [Saya] - [Pengaturan Sistem] - [Batalkan Akun]. Anda dapat membatalkan akun yang telah Anda daftarkan kapan saja, harap merujuk ke 'Petunjuk Pembatalan' untuk informasi lebih lanjut.",
  t92: `Setelah Anda membatalkan akun, kami akan berhenti menyediakan produk atau layanan kepada Anda dan menghapus informasi pribadi Anda, kecuali ditentukan lain oleh hukum dan peraturan.`,
  t93: `Dalam situasi berikut, kami tidak dapat memenuhi permintaan penghapusan informasi pribadi Anda sesuai dengan hukum:`,
  t94: `1. Secara langsung terkait dengan keamanan nasional atau pertahanan nasional;`,
  t95: `2. Secara langsung terkait dengan keamanan publik, kesehatan publik, atau kepentingan publik utama;`,
  t96: `3. Secara langsung terkait dengan penyelidikan, penuntutan, pengadilan, dan pelaksanaan keputusan pidana;`,
  t97: `4. Ada bukti yang cukup bahwa Anda memiliki niat jahat atau penyalahgunaan hak;`,
  t98: `5. Tanggapan terhadap permintaan Anda akan menyebabkan kerusakan serius terhadap hak dan kepentingan hukum Anda atau orang lain atau organisasi lain;`,
  t99: `6. Melibatkan rahasia bisnis.`,
  t100: `(7) Menanggapi permintaan Anda di atas`,
  t101: "Anda dapat memilih izin yang akan dinonaktifkan melalui fungsi 'Pengaturan' di ponsel Anda.",
  t102: "Anda juga dapat menghubungi layanan pelanggan melalui klik 'Saya' - 'Tentang Kami' - 'Hubungi Layanan Pelanggan' untuk melakukan panggilan telepon, kemudian hubungi layanan pelanggan untuk permintaan informasi pribadi, koreksi informasi pribadi, penghapusan informasi pribadi, pembatalan akun pengguna, serta pencabutan izin yang telah diberikan. Untuk memastikan keamanan, Anda mungkin perlu mengajukan permintaan tertulis atau cara lain untuk membuktikan identitas Anda. Sebelum memproses permintaan Anda, kami mungkin akan meminta Anda untuk memverifikasi identitas Anda terlebih dahulu.",
  t103: `Kami akan memberikan tanggapan dalam waktu lima belas hari kerja. Jika Anda tidak puas, Anda juga dapat mengajukan banding melalui layanan pelanggan.`,
  t104: `6. Bagaimana Kami Menangani Informasi Pribadi Anak`,
  t105: `Produk, situs web, dan layanan kami terutama ditujukan untuk orang dewasa. Kami sangat memperhatikan perlindungan informasi pribadi anak-anak dan remaja. Jika Anda adalah seorang remaja di bawah usia 18 tahun, sebelum menggunakan layanan terkait di platform ini, Anda harus membaca dan menyetujui kebijakan privasi ini di bawah pengawasan dan bimbingan orang tua atau wali lainnya dan menggunakan layanan kami atau memberikan informasi kepada kami dengan persetujuan wali.`,
  t106: `Untuk informasi pribadi remaja yang kami kumpulkan saat menggunakan produk atau layanan kami dengan persetujuan orang tua atau wali, kami hanya akan menggunakan, membagikan, mentransfer, atau mengungkapkan informasi tersebut jika diperbolehkan oleh hukum, secara eksplisit mendapat persetujuan dari orang tua atau wali, atau diperlukan untuk melindungi remaja. Jika kami menemukan bahwa kami telah mengumpulkan informasi pribadi remaja tanpa persetujuan orang tua yang dapat diverifikasi, kami akan segera menghapus informasi terkait.`,
  t107: `7. Bagaimana Kami Memindahkan Informasi Pribadi Anda Secara Global`,
  t108: `Saat ini, kami tidak akan mentransmisikan atau menyimpan informasi pribadi Anda secara lintas batas. Jika di masa depan perlu untuk transmisi atau penyimpanan lintas batas, kami akan memberi tahu Anda tentang tujuan ekspor informasi, pihak penerima, langkah-langkah keamanan, serta risiko keamanan ekspor informasi dan mendapatkan persetujuan Anda.`,
  t109: `8. Bagaimana Memperbarui Kebijakan Ini`,
  t110: `Setelah memperbarui kebijakan privasi ini, platform ini akan menampilkan kebijakan privasi yang diperbarui dan kontennya kepada Anda dalam bentuk pemberitahuan, jendela pop-up, pengumuman situs web, dll. saat Anda masuk dan memperbarui versi, sehingga Anda dapat mengetahui kebijakan privasi terbaru ini tepat waktu. Kami akan secara ketat mengikuti aturan pengumpulan dan penggunaan informasi pribadi yang diungkapkan kepada Anda dalam kebijakan privasi untuk melakukan aktivitas pengolahan informasi pribadi. Jika tujuan penggunaan informasi pribadi berubah, kami akan kembali meminta persetujuan Anda. Jika Anda terus menggunakan layanan kami, itu berarti Anda setuju untuk menerima isi revisi kebijakan ini.`,
  t111: `9. Penerapan Kebijakan Ini`,
  t112: `Semua layanan produk ini tunduk pada kebijakan ini.`,
  t113: `Saat Anda pertama kali masuk ke produk ini, kami akan meminta Anda untuk membaca kebijakan ini dan mendapatkan persetujuan Anda. Kecuali disepakati lain atau diwajibkan oleh hukum, kebijakan privasi ini tidak berlaku untuk produk atau layanan pihak ketiga yang ditautkan melalui tautan dari ${window.company} Technology dan pemegang hak cipta terdaftar produk ini. Penggunaan Anda atas layanan pihak ketiga ini (termasuk informasi pribadi apa pun yang Anda berikan kepada pihak ketiga ini) akan tunduk pada ketentuan layanan dan kebijakan privasi pihak ketiga tersebut (bukan kebijakan privasi ini). Harap lindungi informasi pribadi Anda dengan baik, dan hanya berikan kepada pihak ketiga jika diperlukan.`,

  p1: `Kebijakan Privasi ini (selanjutnya disebut sebagai "kebijakan ini") diterbitkan dan berlaku sejak 15 Maret 2023, dan terakhir diperbarui pada 10 April 2023. Kebijakan ini adalah kontrak yang sah antara ${window.region}${window.company} Technology Co., Ltd. (selanjutnya disebut sebagai "${window.company} Technology", "${window.company}" atau "kami") dan pengguna (juga disebut sebagai "Anda") mengenai perlindungan privasi terkait produk klien "Hippo Theater" (selanjutnya disebut sebagai "produk ini"). Dengan mengklik untuk mengonfirmasi Perjanjian ini atau dengan cara lain memilih untuk menerima Perjanjian ini, Anda telah mencapai kesepakatan dengan ${window.company} Technology dan setuju untuk menerima semua ketentuan kebijakan ini.`,
  p2: `${window.region}${window.company} Technology Co., Ltd. adalah pemegang hak cipta terdaftar dari produk ini, dan memiliki hak cipta atas produk ini secara hukum.`,
  p3: `Produk ini dioperasikan bersama oleh ${window.company} Technology dan ${window.company}, menyediakan layanan kepada Anda, menikmati hak-hak, memenuhi kewajiban secara hukum, dan bertanggung jawab secara hukum.`,
  p4: `Kebijakan ini akan membantu Anda memahami hal-hal berikut:`,
  p5: `1. Bagaimana Kami Mengumpulkan dan Menggunakan Informasi Pribadi Anda`,
  p6: `2. Bagaimana Kami Menggunakan Cookie dan Teknologi Serupa`,
  p7: `3. Bagaimana Kami Membagikan, Mentransfer, dan Mengungkapkan Informasi Pribadi Anda`,
  p8: `4. Bagaimana Kami Melindungi Informasi Pribadi Anda`,
  p9: `5. Hak-hak Anda`,
  p10: `6. Bagaimana Kami Menangani Informasi Pribadi Anak`,
  p11: `7. Bagaimana Kami Memindahkan Informasi Pribadi Anda Secara Global`,
  p12: `8. Bagaimana Memperbarui Kebijakan Ini`,
  p13: `9. Penerapan Kebijakan Ini`,
  p14: `10. Penyelesaian Sengketa`,
  p15: `${window.company} Technology dan pemegang hak cipta terdaftar produk ini sangat memperhatikan perlindungan informasi pribadi. Saat pengguna (selanjutnya disebut sebagai 'Anda') menggunakan produk atau layanan kami, kami mungkin akan mengumpulkan dan menggunakan informasi terkait Anda. Setelah Anda memilih untuk menggunakan produk atau layanan kami, itu berarti Anda mengakui dan menerima isi kebijakan ini serta setiap pembaruan yang mungkin dilakukan kapan saja. Jika Anda tidak setuju, Anda harus menghentikan penggunaan layanan produk ini. Harap baca dan pahami kebijakan privasi ini dengan cermat sebelum menggunakan produk kami.`,
  p16: `1. Harap baca dan pahami dengan cermat semua hak dan batasan yang diatur dalam Perjanjian ini. Setelah Anda menginstal, menyalin, mengunduh, mengakses, atau menggunakan produk ini dengan cara lain, itu dianggap sebagai penerimaan Perjanjian ini, dan setuju untuk tunduk pada ketentuan Perjanjian ini. Anda harus memastikan bahwa Anda mengunduh atau menggunakan produk perangkat lunak ini dari situs web yang ditentukan, dan dari media yang diterbitkan secara sah. Jika tidak, kami tidak bertanggung jawab atas risiko dan kerugian potensial yang mungkin timbul. Kami mengingatkan Anda bahwa jika Anda di bawah usia 18 tahun atau tidak memiliki kapasitas hukum penuh, harap baca Perjanjian ini dengan wali sah Anda, dan perhatikan secara khusus ketentuan terkait penggunaan layanan ini oleh anak di bawah umur. Jika tidak, kecuali ditentukan lain oleh hukum, kami tidak bertanggung jawab atas konsekuensi yang merugikan yang mungkin timbul dari pendaftaran, penggunaan layanan ini, atau pembayaran pengisian ulang yang dilakukan setelahnya. Kami berhak untuk mengakhiri perjanjian pengguna antara kedua belah pihak jika situasi tersebut ditemukan.`,
  p17: `2. Pernyataan Hak`,
  p18: `1. ${window.region}${window.company} Technology Co., Ltd. adalah pemegang hak cipta terdaftar dari perangkat lunak ini dan memiliki hak cipta atas perangkat lunak ini secara hukum.`,
  p19: `2. Tanpa izin tertulis dari ${window.company} Technology dan pemegang hak cipta terdaftar perangkat lunak ini, pengguna tidak boleh secara mandiri melaksanakan, menggunakan, mentransfer, atau memberi wewenang kepada pihak ketiga untuk melaksanakan, menggunakan, mentransfer hak cipta perangkat lunak di atas dan hak kekayaan intelektual lainnya untuk tujuan komersial atau non-komersial apa pun. ${window.company} Technology dan pemegang hak cipta terdaftar perangkat lunak ini berhak untuk mengejar tindakan hukum terhadap pelanggaran tersebut tanpa izin.`,
  p20: `3. Larangan rekayasa balik, dekompilasi, dan disassembler: Pengguna tidak boleh melakukan rekayasa balik, dekompilasi, atau disassembler produk perangkat lunak ini, atau mengubah sumber daya yang dikompilasi dalam file program. Kecuali secara tegas diizinkan oleh hukum, pengguna harus mematuhi batasan dalam Perjanjian ini.`,
  p21: `4. Pemisahan Komponen: Lisensi produk perangkat lunak ini adalah untuk digunakan secara keseluruhan, dan pengguna tidak boleh memisahkan setiap bagian untuk tujuan apa pun.`,
  p22: `5. Izin Terpisah: Jika perlu untuk penjualan komersial, penyalinan, distribusi, termasuk tetapi tidak terbatas pada penjualan perangkat lunak, pra-instalasi, bundling, harus mendapatkan izin tertulis terpisah dari pemegang hak cipta perangkat lunak.`,
  p23: `6. Reservasi Hak: Semua hak lainnya yang tidak secara tegas diizinkan dalam Perjanjian ini tetap dimiliki oleh ${window.company} Technology. Pengguna harus mendapatkan izin tertulis dari ${window.company} Technology saat menggunakan hak lainnya.`,
  p24: `7. Konten video yang disediakan oleh perangkat lunak ini hanya mewakili pendapat dan pandangan penulis, dan tidak terkait dengan ${window.company} Technology dan pemegang hak cipta terdaftar perangkat lunak. Penulis bertanggung jawab penuh atas tanggung jawab hukum.`,
  p25: `3. Penjelasan Pengguna`,
  p26: `1. Fungsi perangkat lunak: Mencari dan menonton drama pendek; mengisi ulang, mengunduh, dan membeli drama pendek; menonton dan menyimpan kemajuan menonton.`,
  p27: `2. Tentang pembayaran konten digital: Semua pendapatan yang dihasilkan dari pembayaran pengguna akan diterima oleh ${window.company} Technology setelah dikurangi biaya yang ditetapkan oleh lembaga pembayaran pihak ketiga sesuai ketentuan. Konten digital dalam perangkat lunak ini mencakup konten gratis dan konten berbayar, yang dibagi menjadi konten gratis terbatas waktu dan konten gratis selamanya. ${window.company} Technology berhak untuk menentukan standar dan metode penetapan biaya dari layanan yang disediakan, dan menyediakan petunjuk tentang standar penetapan biaya di halaman layanan terkait. Pengguna berhak untuk memilih apakah akan membayar biaya yang sesuai untuk melanjutkan penggunaan layanan berbayar yang disediakan oleh perangkat lunak ini. Jika Anda adalah seorang remaja di bawah usia 18 tahun (terutama remaja di bawah usia 14 tahun), Anda harus melakukan pengisian ulang dengan persetujuan wali sah Anda. Setelah mengisi ulang pada perangkat lunak ini, pengguna akan mendapatkan poin menonton yang sesuai, yang dapat digunakan untuk membeli layanan berbayar yang disediakan oleh perangkat lunak ini. Persetujuan Anda atas Perjanjian ini harus dianggap sebagai persetujuan Anda untuk memberikan otorisasi kepada ${window.company} Technology untuk membeli konten episode berikutnya secara otomatis dan pembelian konten seri secara massal sebagai layanan berbayar Anda. Setelah pengguna berhasil mengisi ulang (termasuk remaja yang telah mendapat persetujuan wali sah), kecuali ada keadaan yang tak terduga, kesepakatan atau peraturan hukum yang relevan, pengguna tidak boleh menuntut pengembalian uang penuh atau sebagian dengan alasan yang tidak sah.`,
  p28: `3. Modifikasi dan peningkatan perangkat lunak: Pemegang hak cipta perangkat lunak ini berhak untuk menyediakan versi yang dimodifikasi dan ditingkatkan kepada pengguna kapan saja. Setelah pengguna memilih dan mengonfirmasi, perangkat lunak akan diperbarui dan ditingkatkan, menghasilkan biaya lalu lintas data yang sesuai oleh operator jaringan yang digunakan, yang akan ditanggung oleh pengguna.`,
  p29: `4. Pengguna harus menggunakan perangkat lunak ini secara sah dan mematuhi Perjanjian ini. Pengguna yang tidak berwenang tidak boleh melakukan tindakan berikut, termasuk tetapi tidak terbatas pada:`,
  p30: `(1) Menghapus atau mengubah semua informasi elektronik manajemen hak yang ada pada perangkat lunak ini;`,
  p31: `(2) Dengan sengaja menghindari atau merusak langkah-langkah perlindungan teknis yang diambil oleh pemegang hak cipta untuk melindungi hak cipta perangkat lunak ini;`,
  p32: `(3) Menggunakan perangkat lunak ini untuk menyesatkan dan menipu orang lain;`,
  p33: `(4) Melanggar peraturan negara dengan menghapus, mengubah, menambahkan, atau mengganggu fungsi sistem informasi komputer, yang menyebabkan fungsi sistem informasi komputer tidak normal;`,
  p34: `(5) Mengakses jaringan informasi komputer tanpa izin atau menggunakan sumber daya jaringan informasi komputer;`,
  p35: `(6) Menghapus, mengubah, atau menambahkan fungsi jaringan informasi komputer tanpa izin;`,
  p36: `(7) Menghapus, menyusup, mengubah, atau menambahkan data dan aplikasi yang disimpan, diproses, atau ditransmisikan dalam jaringan informasi komputer tanpa izin;`,
  p37: `(8) Mengganggu operasi normal sistem perangkat lunak atau situs web, dengan sengaja menyebarkan virus komputer dan program perusak lainnya;`,
  p38: `(9) Tindakan lain yang membahayakan keamanan jaringan informasi komputer;`,
  p39: `(10) Penggunaan abnormal lainnya.`,
  p40: `5. Untuk produk perangkat lunak ini yang diunduh dari situs web yang tidak ditentukan atau diperoleh dari media yang didistribusikan secara ilegal, ${window.company} Technology tidak dapat menjamin apakah perangkat lunak tersebut terinfeksi oleh virus komputer, apakah itu menyembunyikan program trojan atau perangkat lunak peretas yang disamarkan. Penggunaan perangkat lunak semacam itu dapat membawa risiko yang tidak dapat diprediksi. Kami menyarankan pengguna untuk tidak mengunduh, menginstal, atau menggunakan perangkat lunak semacam itu secara sembarangan. ${window.company} Technology dan pemegang hak cipta terdaftar perangkat lunak ini tidak bertanggung jawab atas tanggung jawab hukum yang timbul akibat hal tersebut.`,
  p41: `6. Perlindungan Privasi`,
  p42: `${window.company} Technology berkomitmen untuk mematuhi kebijakan privasi (juga disebut sebagai "Perjanjian Privasi") dan hukum yang berlaku, serta telah menetapkan empat prinsip perlindungan privasi berikut untuk membimbing kami dalam menangani masalah privasi dan informasi pengguna dalam produk:`,
  p43: `(1) Kami akan memberi tahu Anda secara jelas tentang metode, ruang lingkup, dan tujuan pengumpulan informasi sebelum mengumpulkannya dan mendapatkan persetujuan Anda. Kami hanya akan menggunakan informasi yang kami kumpulkan untuk menyediakan produk dan layanan yang berharga bagi pengguna. Kami tidak akan pernah melebihi ruang lingkup atau frekuensi pengumpulan informasi pribadi Anda, seperti informasi kontak, lokasi, kartu identitas, informasi wajah, dan lainnya, serta tidak akan mengumpulkan informasi yang tidak perlu atau tidak memiliki skenario aplikasi yang wajar untuk layanan perangkat lunak ini;`,
  p44: `(2) Mengembangkan produk yang sesuai dengan standar privasi dan praktik privasi. Tanpa persetujuan pengguna, informasi pribadi, seperti informasi identifikasi perangkat, riwayat penelusuran produk, kebiasaan penggunaan pencarian, dan daftar aplikasi yang sering digunakan, tidak boleh dibagikan atau digunakan bersama dengan aplikasi perangkat lunak lainnya. Kami tidak akan pernah menggunakan informasi pribadi yang dikumpulkan, seperti pencarian pengguna, riwayat penelusuran, kebiasaan penggunaan, dll., untuk promosi tertarget atau pemasaran yang tepat tanpa memberi tahu atau memberi tanda yang jelas kepada pengguna, serta tidak akan menyediakan opsi bagi pengguna untuk menonaktifkan fungsi ini;`,
  p45: `(3) Mengumpulkan informasi pribadi secara transparan;`,
  p46: `(4) Melakukan segala upaya untuk melindungi informasi pengguna yang kami peroleh.`,
  p47: `Berdasarkan hal tersebut, Anda setuju untuk memberi otorisasi kepada ${window.company} Technology untuk menggunakan avatar pengguna dan nama panggilan Anda, agar kami dapat melayani Anda dengan lebih baik.`,
  p48: `7. Setelah menjadi anggota VIP, selama periode keanggotaan, Anda dapat menonton semua konten drama pendek di platform ini.`,
  p49: `8. Selama penginstalan dan menjalankan perangkat lunak ini, ${window.company} Technology tidak akan pernah meminta izin yang tidak terkait dengan skenario penggunaan saat ini. Selain itu, setelah pengguna secara tegas menolak permintaan izin, kami tidak boleh sering mengajukan permintaan untuk mengaktifkan izin yang tidak terkait dengan skenario layanan saat ini, seperti daftar kontak, lokasi, pesan teks, perekaman, kamera, dll., untuk memaksa pengguna setuju mengaktifkan izin yang tidak terkait dengan perangkat lunak ini. Ketika pengguna tidak menggunakan fungsi atau layanan terkait, kami tidak boleh mengajukan permintaan untuk mengaktifkan izin kontak, lokasi, pesan teks, perekaman, kamera, atau izin lain yang tidak terkait dengan fungsi bisnis atau layanan produk perangkat lunak ini di muka. Izin clipboard hanya akan digunakan dalam skenario di mana pengguna memilih untuk menyalin nomor layanan pelanggan. Jika pengguna tidak memilih untuk menyalin, ${window.company} Technology tidak akan meminta pengguna untuk mengaktifkan izin clipboard.`,
  p50: `9. Pengguna berhak untuk membatalkan informasi akun pribadi mereka. Setelah pengguna membatalkan informasi akun pribadi mereka, ${window.company} Technology akan menghapus informasi akun pribadi pengguna yang disimpan dalam perangkat lunak ini (silakan lihat pemberitahuan pembatalan terkait untuk detail lebih lanjut).`,
  p51: `10. Ketika Anda menginstal ulang sistem ponsel atau membersihkan perangkat lunak aplikasi ponsel, semua informasi pengguna Anda akan hilang, termasuk tetapi tidak terbatas pada: informasi akun pribadi, riwayat penelusuran atau pencarian serial, serial yang diunduh atau dibeli, riwayat menonton, kemajuan menonton, hak keanggotaan, dan semua catatan menonton dalam akun pribadi Anda akan diatur ulang menjadi nol. Anda mengetahui bahwa ini adalah sifat produk, tetapi Anda dapat menghindari kehilangan informasi pengguna terkait saat menginstal ulang sistem ponsel atau membersihkan perangkat lunak aplikasi dengan mengaitkan akun pribadi Anda dengan nomor ponsel. Jika Anda tidak mengaitkan akun pribadi Anda dengan nomor ponsel, ${window.company} Technology dan pemegang hak cipta terdaftar perangkat lunak ini tidak akan bertanggung jawab atas insiden kehilangan informasi pengguna tersebut.`,
  p52: `11. Anda tidak boleh terlibat dalam aktivitas ilegal atau konsumsi abnormal dalam platform perangkat lunak ini dengan cara apa pun, atau menggunakan celah sistem, kesalahan program, kesalahan operasi manusia, dan metode lainnya. Anda tidak boleh menggunakan perangkat lunak jahat atau cara lainnya untuk mengganggu operasi normal platform perangkat lunak ini. Anda tidak boleh terlibat dalam aktivitas yang melanggar prinsip kejujuran. Jika tidak, setelah platform perangkat lunak ini menentukan bahwa Anda terlibat dalam perilaku tersebut, ${window.company} Technology berhak untuk membekukan atau membatalkan akun Anda secara langsung, dan tidak akan mengembalikan saldo akun. Semua kerugian yang timbul akan ditanggung oleh Anda. Selain itu, ${window.company} Technology masih berhak untuk mengejar tanggung jawab hukum Anda sesuai dengan hukum. Jika tindakan Anda menyebabkan kerugian pada ${window.company} Technology, Anda harus mengganti semua kerugian yang ditimbulkan oleh ${window.company} Technology. Jika Anda diduga melakukan kejahatan, ${window.company} Technology berhak untuk menyerahkan petunjuk terkait ke departemen peradilan untuk diproses.`,
  p53: `Kami sangat memperhatikan perlindungan informasi pribadi dan hak-hak sah anak di bawah umur, serta mendorong mereka untuk tumbuh dengan sehat. Anak di bawah umur (terutama yang berusia di bawah empat belas tahun) yang menggunakan layanan dalam Perjanjian ini tanpa didampingi dan persetujuan orang tua (wali) yang sah, harus segera memberi tahu kami jika ditemukan. Kami akan membantu orang tua (wali) yang sah untuk menangani akun dan informasi mereka, serta memastikan bahwa kewajiban dan tanggung jawab pengawasan mereka terpenuhi.`,
  p54: `4. Perlindungan Hak Kekayaan Intelektual Pengguna dan Pihak Ketiga`,
  p55: `1. Saat menggunakan layanan yang disertakan dalam perangkat lunak ini, pengguna mungkin perlu memberikan konten kepada ${window.company} Technology melalui berbagai cara seperti mengirim komentar. Semua konten asli yang diposting oleh pengguna di perangkat lunak ini adalah milik pengguna sendiri. Komentar yang diposting oleh pengguna hanya mewakili pandangan mereka sendiri dan tidak terkait dengan pemegang hak cipta terdaftar perangkat lunak atau ${window.company} Technology.`,
  p56: `2. Pengguna tidak boleh melanggar hak kekayaan intelektual, termasuk hak cipta dan hak lainnya milik orang lain. Jika konten yang diposting oleh pengguna menyebabkan sengketa hukum, tanggung jawab akan ditanggung oleh pengguna sendiri.`,
  p57: `3. Untuk konten apa pun yang diposting oleh pengguna di perangkat lunak ini, pengguna setuju untuk memberi ${window.company} Technology hak penggunaan gratis, permanen, non-eksklusif di seluruh dunia. Pengguna memberi ${window.company} Technology izin untuk mengambil tindakan hukum secara mandiri terhadap pelanggaran apa pun (termasuk tetapi tidak terbatas pada tuntutan hukum, pengaduan, pengiriman surat pengacara, dll.).`,
  p58: `4. Jika komentar pengguna melanggar hak cipta atau hak lain pihak ketiga, atau melanggar peraturan hukum, setelah pihak ketiga mengajukan keberatan atau ditemukan melalui proses peninjauan konten, ${window.company} Technology berhak untuk menghapus konten terkait dan berhak untuk mengejar atau membantu pihak ketiga mengejar tanggung jawab hukum pengguna.`,
  p59: `5. Tanpa izin tertulis dari ${window.company} Technology, entitas mana pun tidak boleh menggunakan program crawler, program spider, program manusia, teknologi infiltrasi, program peretas, dan perangkat otomatis lainnya, atau program manual untuk menyusup, membaca, menyalin, menyimpan perangkat lunak ini atau konten apa pun yang disertakan di dalamnya, atau terlibat dalam aktivitas ilegal atau tidak jujur lainnya. Jika tidak, ${window.company} Technology berhak untuk membekukan atau membatalkan akun Anda secara langsung dan berhak untuk tidak mengembalikan saldo akun. Semua kerugian yang timbul akan ditanggung oleh Anda. Selain itu, ${window.company} Technology masih berhak untuk mengejar tanggung jawab hukum atas semua kerugian yang disebabkan oleh perilaku Anda tersebut untuk melindungi hak-hak sah ${window.company} Technology.`,
  p60: `5. Pernyataan Hak Cipta Konten`,
  p61: `${window.company} Technology berkomitmen untuk mematuhi dan menghormati perlindungan hak cipta pemegang hak sesuai dengan hukum, peraturan, dan dokumen normatif lainnya yang berlaku di Tiongkok. Sebagai pemilik hak, jika Anda menemukan bahwa konten yang disediakan melanggar hak-hak sah Anda, Anda harus mengirimkan "Pemberitahuan Hak" kepada kami terlebih dahulu sesuai dengan proses penanganan ke cs{'@'}ishugui.com atau menghubungi layanan pelanggan di 400-118-0066. Kami akan mengambil langkah-langkah sesuai dengan hukum dan peraturan Tiongkok dan dokumen normatif pemerintah untuk menghapus konten terkait atau memutus atau memblokir tautan terkait. Setelah mengambil langkah-langkah tersebut, Anda tidak boleh mengejar tanggung jawab hukum kami dan pemegang hak cipta terdaftar perangkat lunak ini.`,
  p62: `6. Penyangkalan dan Batasan Tanggung Jawab`,
  p63: `1. Pengguna mengonfirmasi bahwa mereka telah memahami semua fungsi perangkat lunak ini dan operasi yang diperlukan untuk mengimplementasikan setiap fungsi. Mereka secara sukarela memilih untuk menggunakan perangkat lunak ini dan layanan terkait sesuai dengan kebutuhan mereka sendiri. Berdasarkan hukum yang berlaku, Anda akan sepenuhnya menanggung risiko dan semua konsekuensi dari penggunaan perangkat lunak ini dan layanan terkait. Pemegang hak cipta terdaftar perangkat lunak dan ${window.company} Technology tidak bertanggung jawab.`,
  p64: `2. Perangkat lunak ini telah diuji secara detail, tetapi kami tidak dapat menjamin kompatibilitas penuh dengan semua sistem perangkat lunak dan perangkat keras, juga tidak dapat menjamin bahwa perangkat lunak ini sepenuhnya bebas dari kesalahan. Jika ada masalah kompatibilitas dan kesalahan perangkat lunak, pengguna dapat menghubungi layanan pelanggan di 400-118-0066 untuk melaporkan situasi dan mendapatkan dukungan teknis. Jika masalah kompatibilitas tidak dapat diselesaikan, pengguna dapat menghapus perangkat lunak ini.`,
  p65: `3. Dalam batasan hukum yang diizinkan, pemegang hak cipta terdaftar perangkat lunak dan ${window.company} Technology tidak bertanggung jawab atas kerusakan atau risiko apa pun yang disebabkan oleh penggunaan atau ketidakmampuan untuk menggunakan perangkat lunak ini, termasuk tetapi tidak terbatas pada kerusakan langsung atau tidak langsung terhadap individu, kehilangan keuntungan bisnis, gangguan perdagangan, kehilangan informasi bisnis atau kerugian ekonomi lainnya.`,
  p66: `4. Pemegang hak cipta terdaftar perangkat lunak dan ${window.company} Technology tidak bertanggung jawab atas kerusakan atau kehilangan informasi yang disebabkan oleh kegagalan sistem telekomunikasi atau jaringan internet, kegagalan ponsel, atau program virus, masalah sistem ponsel, atau alasan lain yang tidak dapat diatasi.`,
  p67: `5. Jika pengguna melanggar ketentuan Perjanjian ini, menyebabkan kerugian pada pemegang hak cipta terdaftar perangkat lunak dan ${window.company} Technology, ${window.company} Technology berhak untuk mengambil tindakan termasuk tetapi tidak terbatas pada menghentikan izin penggunaan pengguna, menghentikan penyediaan layanan, membatasi penggunaan, dan mengejar tanggung jawab hukum.`,
  p68: `7. Hukum dan Penyelesaian Sengketa`,
  p69: `1. Perjanjian ini diatur oleh hukum Republik Rakyat Tiongkok.`,
  p70: `2. Setiap sengketa yang timbul dari Perjanjian ini atau yang terkait dengannya harus diselesaikan melalui negosiasi damai antara para pihak; jika negosiasi gagal, setiap pihak berhak untuk mengajukan sengketa tersebut ke komisi arbitrase untuk arbitrase.`,
  p71: `8. Ketentuan Lain`,
  p72: `1. Jika ketentuan dalam Perjanjian ini dinyatakan seluruhnya atau sebagian tidak sah atau tidak dapat dilaksanakan karena alasan apa pun, atau melanggar hukum yang berlaku, ketentuan tersebut harus dianggap telah dihapus, tetapi ketentuan lainnya dalam Perjanjian ini tetap berlaku dan mengikat.`,
  p73: `2. ${window.company} Technology berhak untuk mengubah Perjanjian ini kapan saja sesuai dengan perubahan hukum dan peraturan yang relevan serta status operasional dan strategi perusahaan. Perjanjian yang direvisi akan dipublikasikan di situs web dan disertakan dalam versi perangkat lunak yang baru. Jika ada sengketa, teks perjanjian terbaru yang berlaku. Jika pengguna tidak setuju dengan perubahan tersebut, mereka dapat menghapus perangkat lunak. Jika pengguna terus menggunakan perangkat lunak ini, itu dianggap bahwa Anda telah menerima perubahan Perjanjian ini.`,
  p74: `3. ${window.company} Technology memiliki hak untuk menafsirkan dan mengubah Perjanjian ini, tetapi tidak boleh melebihi batasan hukum yang diizinkan.`,

  login_first: `Anda belum login, silakan login terlebih dahulu`,
  sessionTimeout: `Sesi telah habis, silakan login kembali`,
  unknown_err: `Kesalahan tidak diketahui`,
  way: `Cara`,

  starCardPlan: `Rencana Bintang`,
  starCardPrivate: `Keistimewaan Bintang`,
  starCardIntroduction: `Kartu Bintang adalah tiket bulanan untuk sistem promosi Jubaopen. Dengan memiliki Kartu Bintang, Anda dapat menikmati serangkaian manfaat dan keuntungan dari sistem promosi Jubaopen. Kartu Bintang terbagi menjadi Kartu Bintang Uji Coba dan Kartu Bintang Eksklusif. Selama periode subsidi miliaran, pengguna Kartu Bintang dapat memperoleh subsidi tunai.`,

  banQuanZhongChou: `Crowdfunding Hak Cipta`,
  banQuan1: `1.3x pengembalian tetap, lebih banyak hadiah fleksibel`,
  banQuan2: `1.5x pengembalian tetap, lebih banyak hadiah fleksibel`,

  enterPhoneNumber: `Masukkan nomor ponsel Anda`,
  enterRightNumber: `Masukkan nomor ponsel yang benar`,
  pwdNotNull: `Kode verifikasi tidak boleh kosong`,
  checkAgreement: `Silakan centang setuju dengan kebijakan privasi dan perjanjian pengguna terlebih dahulu`,
  second: `detik`,
  sendSuccess: `Berhasil dikirim`,
  codeValid: `Dapatkan kode verifikasi`,

  promoteCount: `Jumlah Promosi`,
  today: `Hari ini`,
  promoteList: `Daftar Promosi`,
  nickName: `Nama Panggilan`,
  level: `Tingkat`,
  register: `Daftar`,
  noMore: `Tidak ada konten lagi`,
  page: `Halaman`,
  drawout: `Ajukan penarikan`,
  logining: `Sedang masuk...`,
  shortMoive: `Drama Pendek`,
  otherLogin: `Cara masuk lainnya`,

  uploading: `Sedang mengunggah...`,
  timeout: `Koneksi habis waktu, silakan periksa jaringan`,
  uploadLimitOne: `Pilih hanya satu gambar untuk diunggah`,
  unit: window.currency,

  drawoutNotice: `<p>1. Harap isi informasi terkait penarikan dengan jujur. Jika terjadi kerugian karena informasi yang salah, platform tidak akan bertanggung jawab.</p>
  <p>2. Pesanan penarikan memerlukan tinjauan keuangan, dan mungkin ada sedikit keterlambatan dalam waktu kedatangan.</p>
  <p>3. Jumlah penarikan minimum untuk satu transaksi ${window.currency}$extractSingleMin。</p>
  <p>4. Jumlah penarikan maksimum per transaksi ${window.currency}$extractSingleMax。</p>
  <p>5. Komisi $extractChargeRate , pemotongan dari jumlah penarikan。</p>
  <p>6. Maksimum penarikan harian $extractDailyCount pesanan</p>
  <p>7. Diterima dalam waktu 24 jam setelah penarikan。</p>`,

  submitItem: `Kirim`,
  caresure: `Perhatian`,
  server_bank: `Bank`,
  server_bankCardNo: `Nomor Kartu Bank`,
  server_bankCardHolder: `Nama Akun`,
  server_email: `Email`,
  server_phone: `Telepon`,

  pleaseInputPwd: `Silakan masukkan kata sandi`,
  useCodeLogin: `Beralih ke login kode verifikasi`,
  usePwdLogin: `Beralih ke login kata sandi`,
  pwdLimitTip: `Panjang kata sandi harus 6-20 karakter`,
  pleaEnterInviteCode: `Silakan masukkan kode undangan`,
  setPwd: `Setel kata sandi login`,
  pwdPlaceholder: `Silakan masukkan kata sandi 6-20 karakter`,
  optSuccess: `Operasi berhasil`,

  Balance: `Saldo`, // Balance
  WithdrawAmountTip: `Jumlah penarikan tidak boleh kosong`, // Withdrawal Amount cannot be empty
  WithdrawAmountTipMin: `Saldo Anda kurang dari jumlah penarikan minimum`, // Your balance is less than the minimum withdrawal amount
  WithdrawAmountTipDecimal: `Jumlah penarikan tidak boleh melebihi dua tempat desimal`, // The withdrawal amount cannot exceed two decimal places
  TransferAmountTip: `Jumlah transfer tidak boleh kosong`, // Transfer amount cannot be empty
  TransferAmountTipMin: `Saldo Anda kurang dari jumlah transfer minimum`,
  TransferAmountTipDecimal: `Jumlah transfer tidak boleh melebihi dua tempat desimal`,
  Submit: `Kirim`,

  BankOfDeposit: `Bank Setoran`, // Deposit of Bank
  BankOfDepositTip: `Nama bank tidak boleh kosong`, // Bank name cannot be empty
  BankCardNumber: `Nomor Kartu Bank`, // Bank Card Number
  BankCardNumberTip: `Nomor kartu bank tidak boleh kosong`, // Bank card number cannot be empty
  userName: `Nama Pengguna`, // Username
  userNameTip: `Nama pengguna tidak boleh kosong`, // Username cannot be empty

  TransferUserID: `ID Pengguna Transfer`, // Transfer User ID
  TransferUserIDTip: `ID Pengguna Transfer tidak boleh kosong`, // Transfer user ID cannot be empty
  ReTransferUserID: `Masukkan Ulang ID Pengguna Transfer`, // Re-enter Transfer User ID
  ReTransferUserIDTip: `Masukkan Ulang ID Pengguna Transfer tidak boleh kosong`, // Re-enter Transfer User ID cannot be empty
  TransferUserIDTips: `ID Pengguna Transfer yang dimasukkan ulang tidak cocok dengan ID Pengguna Transfer`, // The re-entered transfer user ID does not match the transfer user ID

  VerificationCode: `Kode Verifikasi`, // Verification Code
  VerificationCodeTip: `Kode verifikasi tidak boleh kosong`, // The verification code cannot be empty
  GetVerificationCode: `Dapatkan Kode Verifikasi`, // Get Verification Code

  Precautions: `Perhatian`, // Precautions
  PrecautionsTip1: `1. Harap isi informasi terkait penarikan dengan benar. Jika terjadi kerugian akibat informasi yang salah, platform tidak bertanggung jawab.`, // 1.Please truthfully fill in the withdrawal related information. If any loss is caused due to incorrect information, the platform will not be held responsible.
  PrecautionsTip2: `2. Permintaan penarikan memerlukan tinjauan keuangan, dan mungkin ada keterlambatan dalam waktu kedatangan.`, // 2.Withdrawal orders require financial review, and there may be a certain delay in the arrival time.
  PrecautionsTip3: `3. Jumlah penarikan minimum untuk satu transaksi slot.`, // 3.Minimum withdrawal amount for a single transaction slot.
  PrecautionsTip4: `4. Jumlah penarikan maksimum per transaksi slot.`, // 4.Maximum withdrawal amount per transaction slot.
  PrecautionsTip5: `5. Komisi slot, dikurangi dari jumlah penarikan.`, // 5.Commission slot,deduction from withdrawal amount.
  PrecautionsTip6: `6. Maksimum penarikan harian slot order`, // 6.Maximum daily withdrawal slot orders
  PrecautionsTip7: `7. Diterima dalam waktu 24 jam setelah penarikan.`, // 7.Received within 24 hours after withdrawal.

  confirmPay: `Konfirmasi Pembayaran $y`,
  goPay: `Bayar Sekarang $y`,
  couponState: `Sudah digunakan atau tidak sesuai dengan jenis saat ini`,
  couponNo: `Kupon saat ini tidak tersedia`,
  coupinErr: `Kesalahan Kupon`,
  couponUse: `Diskon Kupon $y`,
  BalanceDeductions: ` Diskon saldo $y`,
  ExchangeStarCard: `Tukar Kartu Bintang`,
  MasukkanKeanggotaan: `Tukarkan M-Card atau M-Card Pro selama -x hari`,
  ExchangeNotUsed: `Kode pertukaran belum digunakan`,
  ExchangeUsed: `Kode pertukaran sudah digunakan`,
  noExchange: `Kode pertukaran tidak ada`,
  PleaseAmount: `Silakan pilih jumlah partisipasi:`,
  PaymentAgreement: `Pembayaran dianggap sebagai persetujuan Anda`,
  DramaAgreement: `Perjanjian Layanan Drama Rich Star Card`,
  setPayment: `Atur Metode Pembayaran`,
  saveInfo: `Simpan Informasi`,
  myBuy: `Beli`,
  mySell: `Jual`,
  placeOrder: `Tempatkan Pesanan`,
  whole: `Semua`,
  myOrder: `Pesanan Saya`,
  anonymousUser: `Pengguna Anonim`,
  deal: `Transaksi`,
  sell: `Jual`,
  integral: `Poin`,
  demand: `Permintaan`,
  minute: `Menit`,
  sellOut: `Jual Habis`,
  purchase: `Beli`,
  pendingOrders: `Pesanan Tertunda`,
  confirmOrder: `Konfirmasi Pesanan`,
  choosePaymentMethod: `Pilih Metode Pembayaran`,
  back: `Kembali`,
  orderGenerated: `Pesanan Dihasilkan`,
  please: `Silakan`,
  paymentInformation: `Konfirmasi Informasi Pembayaran Pembeli`,
  reportExceptions: `Laporkan Kejadian`,
  sellerToConfirm: `Menunggu Konfirmasi Pembayaran dari Penjual`,
  ordersCannotStage: `Pesanan Tidak Dapat Dibatalkan pada Tahap Ini`,
  orderCompleted: `Pesanan Selesai`,
  contactustomer: `Jika ada keberatan, silakan hubungi layanan pelanggan untuk mediasi`,
  customerServiceMediation: `Mediasi Layanan Pelanggan`,
  confirmCompletion: `Konfirmasi Penyelesaian`,
  PleaseWaitPayment: `Silakan Tunggu Pembayaran Pembeli`,
  reportCorrect: `Laporkan Kejadian Pesanan, Apakah Ini Benar?`,
  addMethod: `Tambahkan Metode Pembayaran`,
  selectType: `Pilih Tipe`,
  sellPoints: `Jual Poin`,
  sellUsers: `Jual Poin dari Akun ke Pengguna Lain`,
  purchasePoints: `Beli Poin`,
  canPointsUsers: `Dapat Membeli Poin dari Pengguna Lain`,
  messageBoard: `Papan Pesan`,
  you: `Anda`,
  reply: `Balas`,
  enterBelow: `Silakan Masukkan Informasi Balasan di Bawah`,
  viewDetails: `Lihat Detail`,
  paymentMethod: `Metode Pembayaran`,
  addPaymentInformation: `Tambahkan Informasi Pembayaran`,
  paymentDeadline: `Batas Waktu Pembayaran`,
  totalAmount: `Jumlah Total`,
  price: `Harga Satuan`,
  tradingPrecautions: `Peringatan Perdagangan`,
  afterQuantity: `1. Setelah mengonfirmasi pesanan, poin Anda akan dikunci sesuai dengan jumlah pesanan.`,
  confirmCancelled: `2. Harap konfirmasi penyelesaian transaksi dalam waktu yang ditentukan, jika tidak, transaksi akan dibatalkan secara otomatis.`,
  cancellationOrder: `Batalkan Pesanan`,
  CancelOrder: `Batalkan Daftar`,
  transactionDetails: `Detail Transaksi`,
  myPendingOrder: `Pesanan Tertunda Saya`,
  orderType: `Tipe Pesanan`,
  myTransaction: `Transaksi Saya`,
  orderStatus: `Status Pesanan`,
  buyPoints: `Poin Beli`,
  release: `Rilis`,
  locking: `Terkunci`,
  end: `Akhir`,
  removeFromShelves: `Hapus dari Rak`,
  establish: `Buat`,
  approachingTimeout: `Mendekati Batas Waktu`,
  overtime: `Batas Waktu Habis`,
  buyerConfirmed: `Pembeli Dikonfirmasi`,
  sellerConfirmed: `Penjual Dikonfirmasi`,
  successfulTrade: `Perdagangan Berhasil`,
  createOrder: `Buat Pesanan Beli`,
  createSellOrder: `Buat Pesanan Jual`,
  enterPurchaseQuantity: `Masukkan Jumlah Pembelian`,
  enterSale: `Masukkan Jumlah Penjualan`,
  pointsBalance: `Saldo Poin`,
  setMaxQuantity: `Atur Jumlah Maksimum`,
  setPurchasePrice: `Atur Harga Pembelian (Harga Total)`,
  setSalePrice: `Atur Harga Penjualan (Harga Total)`,
  rangePrice: `Rentang Harga Poin per Unit`,
  confirmPeddingOrder: `Konfirmasi Daftar`,
  pointsRange: `Harga Poin per Unit Melebihi Rentang`,
  orderSuccessfully: `Pesanan Berhasil Ditempatkan`,
  OrderErr: `Kesalahan Pesanan`,
  OrderOverTime: `Pesanan Kedaluwarsa`,
  CustomeRservice: `Jika ada keberatan, silakan hubungi layanan pelanggan untuk mediasi`,
  UpdataPedding: `Ubah Pesanan Tertunda`,
  CustomerService: `Mediasi Layanan Pelanggan`,
  WaitingPayment: `Menunggu Pembayaran`,
  Collectionpayment: `Menunggu Penagihan`,
  ConfirmCompletion: `Konfirmasi Penyelesaian`,
  ContactCustomer: `Hubungi Layanan Pelanggan untuk Mediasi`,
  NumberPending: `Jumlah Pesanan Tertunda Hari Ini`,
  Edit: `Edit`,
  Create: `Buat`,
  Bill: `Tagihan`,
  SellBill: `Jual Tagihan`,
  PendingOrders: `Pesanan Tertunda`,
  Reason: `alasan`,
  Kong: `kosong`,
  USDTAmout: `Setelah menarik ke USDT, jumlahnya adalah $slot`,
  enterPassword: `Silakan masukkan kata sandi`,
  enterSecondPassword: `Silakan masukkan konfirmasi kata sandi`,
  confirmPassword: `Kedua kata sandi tidak cocok`,
  registerIn: `Daftar dan masuk sekarang`,
  LoginCode: `Masuk dengan kode verifikasi`,
  LoginPassword: `Masuk dengan kata sandi`,
  goLogin: `Pergi ke login`,
  goRegister: `Pergi ke registrasi`,

  WALLET: 'Dompet',
  THIRD_PAY: 'Pembayaran Ketiga',
  Using: `Sedang Digunakan`,
  SwitchAccount: `Ganti Akun`,
  AddAccount: `Tambah Akun`,
  Finish: `Selesai`,
  ClearAccount: `Apakah Anda yakin ingin membersihkan akun Anda?`,

  AppDesc: `Platform Berbagi Video Pendek yang Inovatif`,
  AppIntroduce: `MovSee adalah platform mutakhir yang menggabungkan hiburan dan peluang penghasilan bagi pengguna yang menikmati menonton drama pendek. Platform ini memungkinkan pengguna untuk menghasilkan uang dengan berinteraksi melalui fitur-fitur interaktif, seperti menonton, berkomentar, dan berbagi drama pendek.`,
  DownloadNow: `Unduh Sekarang`,
  SignIn: `Masuk`,
  MarketBackground: `Latar Belakang Pasar`,
  MarketDesc: `Dengan lebih dari 1 miliar pengguna video pendek secara global, 50,4% di antaranya telah menonton mikro-drama berdurasi di bawah 3 menit. Perkiraan industri menunjukkan bahwa pada tahun 2023, ukuran pasar untuk drama pendek dapat mencapai antara $2,8 miliar hingga $4,2 miliar, dengan pertumbuhan yang terus berlanjut hingga tahun 2024.`,
  ProductFeatures: `Fitur Produk`,
  AppFeature1: `1. Konten Beragam: `,
  AppFeatureDesc1: `MovSee menawarkan perpustakaan drama pendek yang kaya, mencakup genre seperti komedi, romansa, suspense, dan lainnya, yang memenuhi berbagai preferensi pengguna.`,
  AppFeature2: `2.Model Penghasilan: `,
  AppFeatureDesc2: `MovSee memungkinkan pengguna untuk menghasilkan uang nyata dengan menonton, berkomentar, dan berbagi drama pendek. Model monetisasi yang unik ini membuatnya sangat menarik bagi pengguna.`,
  AppFeature3: `3. Komunitas Interaktif: `,
  AppFeatureDesc3: `MovSee membangun rasa komunitas yang kuat dengan memungkinkan pengguna untuk menyukai, mengomentari, dan membagikan konten, mendorong interaksi aktif antar pengguna.`,
  AppFeature4: `4.Rekomendasi Personalisasi: `,
  AppFeatureDesc4: `Dengan menggunakan algoritma cerdas, MovSee memberikan rekomendasi konten yang dipersonalisasi berdasarkan minat pengguna, meningkatkan keterlibatan dan kepuasan`,
  CompetitiveAdvantages: `Keunggulan Kompetitif`,
  CompetitiveAdvantages1: `1.Promosi dan Distribusi Lisensi Terbuka: `,
  CompetitiveAdvantages1_1: `MovSee memanfaatkan lisensi terbuka, memungkinkan konten yang belum terjamah untuk dipromosikan di platform dan mewujudkan potensi komersialnya secara penuh.`,
  CompetitiveAdvantages2: `2.Drama Pendek Berkualitas Tinggi yang Diproduksi Sendiri: `,
  CompetitiveAdvantages2_1: `MovSee berinvestasi dalam menciptakan drama pendek berkualitas tinggi yang orisinal, memperkaya perpustakaan kontennya dan meningkatkan retensi pengguna.`,
  CompetitiveAdvantages3: `3.Manajemen IP Aktor Mikro-Drama: `,
  CompetitiveAdvantages3_1: `Dengan mengembangkan IP untuk aktor mikro-drama, MovSee memanfaatkan pengaruh para aktor untuk meningkatkan visibilitas platform, mendorong keterlibatan pengguna dan konversi lalu lintas.`,
  CardTitle1: `Tata Letak Rantai Industri`,
  CardDesc1: `MovSee mengintegrasikan proses produksi dan distribusi konten, dengan pemilik hak cipta, distributor, dan agen streaming bekerja sama untuk memastikan aliran konten yang lancar dari hulu ke hilir.`,
  CardTitle2: `Sistem Kartu M`,
  CardDesc2: `Kartu M adalah kredensial bagi pengguna untuk berpartisipasi dalam program penghasilan MovSee Pro. Tersedia dalam dua jenis, Kartu M Percobaan dan Kartu M Premium, keduanya memberikan akses ke hadiah tunai dan manfaat lainnya.`,
  CardTitle3: `Investasi Hak Cipta`,
  CardDesc3: `MovSee menawarkan peluang investasi yang unik di mana pengguna dapat berinvestasi dalam promosi drama yang memiliki hak cipta, dengan mendapatkan bagian dari keuntungan yang dihasilkan. Keuntungan tersebut berfluktuasi berdasarkan siklus promosi masing-masing drama.`,
  CardTitle4: `Penghasilan Sosial dan Berbasis Tim`,
  CardDesc4: `MovSee memperkenalkan sistem penghasilan sosial dan berbasis tim, memungkinkan pengguna untuk membentuk tim dan memanfaatkan jaringan mereka untuk memaksimalkan pendapatan. Promosi berbasis tim membuka akses ke hadiah dan bonus tambahan, meningkatkan daya tarik platform.`,
  UserFeedback: `Umpan Balik Pengguna`,
  UserFeadbackDesc: `MovSee telah menerima umpan balik positif dari penggunanya!`,
  Career1: `Pelajar`,
  Career2: `Guru`,
  Career3: `Programmer`,
  Career4: `Supir`,
  UserTalk1: `"Saya bisa menghasilkan uang sambil terhibur—ini luar biasa!"`,
  UserTalk2: `· "Drama pendeknya kaya dan menarik, dan model penghasilannya sangat menarik."`,
  UserTalk3: `"Rekomendasi personalisasi dari MovSee selalu membantu saya menemukan drama yang saya cintai."`,
  UserTalk4: `·"Komunitas interaktif membantu saya membuat teman baru."`,
  Platfrom: `Visi Platform`,
  PlatformDesc: `MovSee bertujuan untuk terus meningkatkan kontennya, pengalaman pengguna, dan jumlah penggunanya sambil menjelajahi strategi monetisasi baru untuk pertumbuhan yang berkelanjutan. Dengan tetap peka terhadap tren pasar dan kebutuhan pengguna, MovSee siap untuk meraih kesuksesan yang berkelanjutan di pasar drama pendek yang terus berkembang`,
  Movshot: `MovSee`,
  Contact: `Kontak`,

  ContactDesc: `Kami adalah tim yang ramah dan kami sangat ingin mendengar ide, umpan balik, dan pertanyaan Anda.`,
  Message: `Pesan...`,
  EmailErr: `Kesalahan format email`,
  FormErr: `Silakan lengkapi formulir yang ada`,
  FormDesc: `Masukkan setidaknya 10 karakter`,
  noPaymentChannel: `Tidak ada saluran pembayaran`,
  pleaSelectCoinType: `Silakan pilih tipe uang`,
  overDelete: `Dibatalkan`,
  InputInvite: `Silakan masukkan kode undangan (opsional)`,
  registerSuccess: `Pendaftaran Berhasil`,
  WebVersion: `versi web`,

  //new Copywriting
  AppDescPro: 'Web3 AI + Matriks Drama Pendek',
  AppIntroducePro:
    'MovSee adalah platform berbagi video pendek yang inovatif di mana pengguna dapat menonton drama pendek yang menarik dan mendapatkan imbalan melalui keterlibatan. Dengan mengintegrasikan teknologi Web3, platform ini menawarkan pengalaman penciptaan dan distribusi yang imersif dan terdesentralisasi, mendorong evolusi industri video pendek.',
  MarketBackgroundPro: 'Tentang MovSee',
  MarketDescPro:
    'MovSee menggabungkan teknologi blockchain dan AI untuk menyediakan lingkungan yang transparan dan bebas untuk penciptaan dan interaksi.',
  ProductFeaturesPro: 'Sorotan Produk',
  AppFeaturePro1: '1. Model Penghasilan: ',
  AppFeatureDescPro1:
    'Pengguna dapat mendapatkan imbalan nyata dengan menonton, mengomentari, dan membagikan drama pendek.',
  AppFeaturePro2: '2. Komunitas Interaktif: ',
  AppFeatureDescPro2:
    'Fitur seperti suka, komentar, dan berbagi mendorong suasana komunitas yang aktif.',
  AppFeaturePro3: '3. Rekomendasi Pribadi: ',
  AppFeatureDescPro3:
    'Algoritma cerdas merekomendasikan drama berdasarkan minat pengguna, meningkatkan pengalaman menonton.',
  AppFeaturePro4: '4. Konten Beragam: ',
  AppFeatureDescPro4:
    'Beragam drama pendek, termasuk komedi, romansa, ketegangan, dan lainnya, memenuhi preferensi menonton yang beragam.',
  CardTitlePro1: 'Program Kartu M',
  CardDescPro1:
    'Kartu M adalah kredensial kunci dalam sistem promosi MovSee Pro, menawarkan pengguna berbagai peluang penghasilan dan imbalan. Pengguna dapat memilih antara Kartu M percobaan atau premium, mendapatkan imbalan uang tunai dan hak istimewa tambahan.',
  CardTitlePro2: 'Penghasilan Sosial dan Tim',
  CardDescPro2:
    'MovSee juga memperkenalkan jaringan sosial dan model penghasilan berbasis tim. Pengguna dapat membentuk tim dan memaksimalkan penghasilan melalui berbagi dan promosi, secara efektif meningkatkan keterlibatan pengguna dan potensi profitabilitas platform.',
  CardTitlePro3: 'Ekosistem Drama Pendek Global',
  CardDescPro3:
    'MovSee mengumpulkan pencipta dan audiens di seluruh dunia untuk bersama-sama membentuk masa depan drama pendek.',
  PlatfromPro: 'Bergabunglah dengan Kami',
  PlatformDescPro:
    'MovSee dengan hangat mengundang pencipta, penonton, dan investor untuk bergabung dengan kami dalam membentuk masa depan drama pendek.',
  EntryTitle1: 'Web3 Inovatif',
  EntryTitle2: 'Pengalaman Drama Pendek',
  EntryContent:
    'Dengan memanfaatkan manfaat desentralisasi dan transfer nilai dari Web3, MovSee sedang mengubah industri video pendek.',
  CompetitiveAdvantagesPro1:
    '1. Penciptaan Terdesentralisasi dan Ekonomi Penggemar: ',
  CompetitiveAdvantagesPro1_1:
    'Mendukung penciptaan terdesentralisasi, menawarkan pengguna peluang baru untuk berpartisipasi dan berinvestasi.',
  CompetitiveAdvantagesPro2:
    '2. Insentif Ekosistem dan Kemitraan Lintas Sektor: ',
  CompetitiveAdvantagesPro2_1:
    'Melalui mekanisme seperti airdrop, perlindungan hak cipta, dan integrasi aset, MovSee menciptakan lebih banyak peluang untuk penghasilan pengguna.',
};
